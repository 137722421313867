import { WL_NUMBERS } from '@/constants/whiteLabels';

export type WLid = (typeof WL_NUMBERS)[keyof typeof WL_NUMBERS];

export const fontConfig = (whiteLabelId: WLid) => {
  switch (whiteLabelId) {
    case WL_NUMBERS.THORN:
      import('@fontsource/ibm-plex-sans/100.css');
      import('@fontsource/ibm-plex-sans/200.css');
      import('@fontsource/ibm-plex-sans/300.css');
      import('@fontsource/ibm-plex-sans/400.css');
      import('@fontsource/ibm-plex-sans/500.css');
      import('@fontsource/ibm-plex-sans/600.css');
      import('@fontsource/ibm-plex-sans/700.css');
      break;
    case WL_NUMBERS.MY_BEAT:
      // @ts-expect-error Cannot find module - it exists
      import('@fontsource-variable/work-sans');
      break;
    case WL_NUMBERS.AXEL:
      const fontFace = new FontFace(
        'Geometric_706_Medium_BT',
        'url(/fonts/2406/Geometric_706_Medium_BT.ttf) format("truetype")',
      );
      fontFace.load().then((loadedFontFace) => {
        document.fonts.add(loadedFontFace);
      });
      break;
    case WL_NUMBERS.PROTEXIA:
      const robotoFontVariants = [
        { name: 'Roboto-Black', url: '/fonts/2408/Roboto-Black.ttf' },
        {
          name: 'Roboto-BlackItalic',
          url: '/fonts/2408/Roboto-BlackItalic.ttf',
        },
        { name: 'Roboto-Bold', url: '/fonts/2408/Roboto-Bold.ttf' },
        { name: 'Roboto-BoldItalic', url: '/fonts/2408/Roboto-BoldItalic.ttf' },
        { name: 'Roboto-Light', url: '/fonts/2408/Roboto-Light.ttf' },
        {
          name: 'Roboto-LightItalic',
          url: '/fonts/2408/Roboto-LightItalic.ttf',
        },
        { name: 'Roboto-Thin', url: '/fonts/2408/Roboto-Thin.ttf' },
        { name: 'Roboto-ThinItalic', url: '/fonts/2408/Roboto-ThinItalic.ttf' },
        { name: 'Roboto-Regular', url: '/fonts/2408/Roboto-Regular.ttf' },
        { name: 'Roboto-Medium', url: '/fonts/2408/Roboto-Medium.ttf' },
        {
          name: 'Roboto-MediumItalic',
          url: '/fonts/2408/Roboto-MediumItalic.ttf',
        },
      ];

      robotoFontVariants.forEach((variant) => {
        const fontFace = new FontFace(
          variant.name,
          `url(${variant.url}) format("truetype")`,
        );
        fontFace.load().then((loadedFontFace) => {
          document.fonts.add(loadedFontFace);
        });
      });
      break;
    default:
      return;
  }
};

export const getFontFamily = (whiteLabelId: WLid) => {
  const fontFamilies = {
    [WL_NUMBERS.THORN]: `'IBM Plex Sans', sans-serif`,
    [WL_NUMBERS.MY_BEAT]: `'Work Sans', sans-serif`,
    [WL_NUMBERS.AXEL]: `'Geometric_706_Medium_BT', sans-serif`,
    [WL_NUMBERS.PROTEXIA]: `'Roboto', sans-serif`,
    [WL_NUMBERS.IDENTIA]: `'Roboto', sans-serif`,
    [WL_NUMBERS.BOOKEDIN]: `'Roboto', sans-serif`,
  } as const;
  return { fontFamily: fontFamilies[whiteLabelId] } || {};
};
